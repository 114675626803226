import $ from 'jquery'

const Munki = (($) => {

//Smooth Scrolling For Internal Page Links
$(function() {
  $('a[href*=\\#spy]:not([href=\\#])').click(function() {
  if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
    if (target.length) {
    $('html,body').animate({
      scrollTop: target.offset().top - 60
    }, 1000);
    return false;
    }
  }
  });
});

var isFirefox = (navigator.userAgent.toLowerCase().indexOf('firefox') > -1);
var isMobile = (typeof window.orientation !== "undefined") ||
               (navigator.userAgent.indexOf('IEMobile') !== -1);

if(isFirefox && !isMobile) {
    $('a[href^="tel:"]').click(function() { return false; });
}

/*

  $(document).ready(function() {
    $(window).keydown(function(event){
      if(event.keyCode == 13) {
        event.preventDefault();
        return false;
      }
    });
  });
*/



/* tests to see if string is in correct UK style postcode: AL1 1AB, BM1 5YZ etc. */ 
function isValidPostcode(p) { 
    var postcodeRegEx = /[A-Z]{1,2}[0-9]{1,2} ?[0-9][A-Z]{2}/i; 
    return postcodeRegEx.test(p); 
}
function isEmail(email) {
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
}
function isPhone(phone) {
    var regex = /^(\(?(0|\+44)[1-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/;
    return regex.test(phone);
}



  window.addEventListener("load", function(){
  var p;

    window.cookieconsent.initialise({
      "palette": {
        "popup": {
          "background": "#aa0000",
          "text": "#ffdddd"
        },
        "button": {
          "background": "#ff0000"
        }
      },
      "position": "bottom",
      "type": "opt-in",
      "content": {
      },
      onPopupOpen: function() {
        //console.log('<em>onPopupOpen()</em> called');
      },
      onPopupClose: function() {
        //console.log('<em>onPopupClose()</em> called');
      },
      onInitialise: function (status) {
        //console.log('<em>onInitialise()</em> called with status <em>'+status+'</em>');
      },
      onStatusChange: function(status) {
        //console.log('<em>onStatusChange()</em> called with status <em>'+status+'</em>');
        var choice = this.hasConsented() ?
        'enable cookies' : 'disable cookies';
        console.log(choice);
        if(choice === 'disable cookies'){
          //console.log('denied');
          var ajaxurl = 'co/deny-EXPRESSMS.php',
          data =  {'action': 'deny'};
          $.post(ajaxurl, data, function (response) {
              // Response div goes here.
          });
        } else {
          //console.log('allowed');
          var ajaxurl = 'co/undelete-EXPRESSMS.php',
          data =  {'action': 'consent'};
          $.post(ajaxurl, data, function (response) {
              // Response div goes here.
          });
        }
        window.location.reload(false);
      },
    onRevokeChoice: function() {
      //console.log('<em>onRevokeChoice()</em> called');
      delete_cookie('_gat');
      delete_cookie('_ga');
      delete_cookie('_gid');
      var ajaxurl = 'co/delete-EXPRESSMS.php',
      data =  {'action': 'revoke'};
      $.post(ajaxurl, data, function (response) {
          // Response div goes here.
      });
      window.location.reload(false); 
    },
  }, function (popup) {
    p = popup;
  }, function (err) {
    console.error(err);
  });

$( ".btn-open" ).click(function(e) {
  //console.log("Calling <em>open()</em>");
  p.open();
});

});
  var delete_cookie = function(name) {
      document.cookie = name + '=; Path=/; Domain=.delifelice.co.uk; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  };



(new IntersectionObserver(function(e,o){
    if (e[0].intersectionRatio > 0){
        document.documentElement.removeAttribute('class');
    } else {
        document.documentElement.setAttribute('class','stuck');
    };
})).observe(document.querySelector('.trigger'));




  return Munki

})($)

export default Munki
